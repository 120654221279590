/* 1.1 typography */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');

body {
  line-height: 31px;
  font-family: $primary-font;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
}

p, .paragraph {
  font-weight: 300;
  color: $text-color;
  font-size: 17px;
  line-height: 31px;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  color: $text-color-dark;
  font-family: $primary-font;
  font-weight: 600;
  line-height: 1.5;
}

h1, .h1{
  font-size: 56px;
}

h2, .h2{
  font-size: 43px;
}

h3, .h3{
  font-size: 37px;
}

h4, .h4{
  font-size: 22px;
}

h5, .h5{
  font-size: 16px;
}

h6, .h6{
  font-size: 13px;
}
