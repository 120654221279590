.about {
  position: relative;
  overflow: visible;
  &-video {
    position: relative;

    .play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-bg-1{
    position: absolute;
    left: 30%;
    top: 10%;
    z-index: -1;
  }
  &-bg-2{
    position: absolute;
    top: 30%;
    right: 40px;
    z-index: -1;
  }
  &-bg-3{
    position: absolute;
    bottom: 20%;
    left: 30px;
    z-index: -1;
  }
  &-bg-4{
    position: absolute;
    top: 20%;
    left: 40px;
    z-index: -1;
  }
  &-bg-5{
    position: absolute;
    bottom: 10%;
    right: 40px;
    z-index: -1;
  }
  &-bg-6{
    position: absolute;
    top: 10%;
    right: 10%;
    z-index: -1;
  }
}


.play-btn {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  color: $white;
  line-height: 70px;
  font-size: 20px;
  text-align: center;
  background: #7aff91;
  display: inline-block;
  transition: .2s ease;
  box-shadow: 0px 23px 43px 0px rgba(94, 254, 198, 0.3);

  &:hover {
    color: $white;
    font-size: 30px;
  }
}

.product{
  position: relative;
  overflow: visible;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.about-bg{
  position: absolute;
  left: 0;
  top: 0px;
  z-index: -1;
}